.gallery {
  width: 100%;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid #dadada;
  padding: 10px;
  position: relative;

  .label {
    position: absolute;
    top: -9px;
    left: 8px;
    background: white;
    padding: 0 6px;
    font-size: 12px;
  }

  .thumbsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .thumb {
    display: inline-flex;
    border-radius: 10px;
    border: 1px solid #eaeaea;
    margin-bottom: 8px;
    margin-right: 8px;
    width: 100px;
    height: 100px;
    padding: 2px;
    box-xizing: border-box;
  }

  .thumbInner {
    display: flex;
    min-width: 0;
    position: relative;

    .remove {
      position: absolute;
      top: -5px;
      right: -5px;
      width: 25px;
      height: 25px;
      min-width: 25px;
      min-height: 25px;
    }

    @mixin img {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      object-position: center;
    }

    .img {
      @include img;
      object-fit: cover;
    }

    .placeHolder {
      @include img;
      object-fit: contain;
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
}

.filePickerCardHeader {
  div {
    span {
      width: 196px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}
